import Karlstad from "./../../../assets/images/fastigheter/fast-one.png";
import Stockholm from "./../../../assets/images/fastigheter/fast-one.png";
import { Location } from "./property";

export default [
	{
		name: "Fullerö, Uppsala",
		type: "Bostadsrättsförening Tallbacken",
		amount: "52 lägenheter",
		builder: "SPG",
		finished: "Q1 2018",
		architect: "Metod Arkitekter",
		contractor: "Consto",
		pos: [59.93847167398548, 17.674841857140418],
		address: "Meteorvägen 30",
		zip: "743 45",
		img: Karlstad,
	},
	{
		name: "Fullerö, Uppsala",
		type: "Hyresrätter",
		amount: "2 st flerbostadshus. 137 lägenheter",
		builder: "SPG",
		finished: "Q4 2018",
		architect: "Metod Arkitekter",
		contractor: "Consto",
		pos: [59.94058764074853, 17.674781028462267],
		address: "Meteorvägen 2",
		zip: "743 45",
		img: Karlstad,
	},
	{
		name: "Fullerö, Uppsala",
		type: "Förskola",
		amount: "8 avdelningar",
		builder: "SPG",
		finished: "Q3 2018",
		architect: "Flexator",
		contractor: "Flexator & Consto",
		pos: [59.93849875919423, 17.67585566850144],
		address: "Meteorvägen 24",
		zip: "743 45",
		img: Karlstad,
	},
	{
		name: "Kungsängen, Upplands-Bro",
		type: "Bostadsrättsförening Brunna Park",
		amount: "138 lägenheter",
		builder: "SPG",
		finished: "Q2 2018",
		architect: "Metod Arkitekter",
		contractor: "Consto",
		pos: [59.4934996992622, 17.737472168392877],
		address: "Femstenavägen 1",
		zip: "196 36",
		img: Karlstad,
	},
	{
		name: "Kungsängen, Upplands-Bro",
		type: "Hyresrätter",
		amount: "139 lägenheter",
		builder: "SPG",
		finished: "Q2 2018",
		architect: "Metod Arkitekter",
		contractor: "Consto",
		pos: [59.494259684950855, 17.7373605407204],
		address: "Hjärtstensvägen 1",
		zip: "196 36",
		img: Karlstad,
	},
	{
		name: "Kungsängen, Upplands-Bro",
		type: "Bostadsrättsförening Handelsparken",
		amount: "65 lägenheter",
		builder: "SPG",
		finished: "Q3 2018",
		architect: "Metod Arkitekter",
		contractor: "Consto",
		pos: [59.494942990809136, 17.737432770390825],
		address: "Hjärtstensvägen 15",
		zip: "196 36",
		img: Karlstad,
	},
	{
		name: "Norra Kajen, Sundsvall",
		type: "Bostadsrättsförening Haga Strand",
		amount: "48 lägenheter",
		builder: "SPG",
		finished: "Q1 2019",
		architect: "Tengbom",
		contractor: "Consto",
		pos: [62.39347238446115, 17.32001939561337],
		address: "Skonaregatan 2",
		zip: "856 35",
		img: Karlstad,
	},
	{
		name: "Bålsta, Håbo kommun",
		type: "Bostadsrättsförening Stationslunden 1 & 2",
		amount: "2 st flerbostadshus. 100 lägenheter",
		builder: "SPG",
		finished: "2022",
		architect: "Tengbom",
		contractor: "NCC",
		pos: [59.56186327190867, 17.542511597752114],
		address: "Herrgårdsgatan, Bålsta",
		img: Karlstad,
	},
	{
		name: "Kv Kaveldunet, Strömstad",
		type: "Bostadsrätter",
		amount: "18 lägenheter",
		finished: "2013",
		architect: "Tengbom",
		pos: [58.946948268845986, 11.198746547446522],
		address: "Tångenvägen, Strömstad",
		img: Karlstad,
	},
	{
		name: "Bryggudden, Karlstad",
		type: "Hyresrätter - Fullriggaren",
		amount: "204 lägenheter",
		builder: "SPG",
		finished: "2018",
		architect: "Tengbom",
		contractor: "Consto",
		pos: [59.37757336104585, 13.510043208312698],
		address: "Bergendorffsgatan, Karlstad",
		img: Karlstad,
	},
	{
		name: "Bryggudden, Karlstad",
		type: "Bostadsrättsförening Hamnstaden",
		amount: "60 lägenheter",
		builder: "SPG",
		finished: "2018",
		architect: "Tengbom",
		contractor: "Consto",
		pos: [59.377347805074045, 13.510153909548222],
		address: "Bergendorffsgatan, Karlstad",
		img: Karlstad,
	},
	{
		name: "Bryggudden, Karlstad",
		type: "Förskola - Fullriggaren",
		amount: "4 avdelningar",
		builder: "SPG",
		finished: "2018",
		architect: "Tengbom",
		contractor: "Consto",
		pos: [59.37798543058224, 13.510366796539614],
		address: "Stapelgatan, Karlstad",
		img: Karlstad,
	},
	{
		name: "Bryggudden, Karlstad",
		type: "Bostadsrättsförening Kanalstranden",
		amount: "43 lägenheter",
		builder: "Löfberg Fastigheter AB",
		finished: "2017",
		architect: "Tengbom",
		contractor: "Consto",
		pos: [59.376185300507764, 13.510520075173417],
		address: "Ahlmarksgatan, Karlstad",
		img: Karlstad,
	},
	/* SAKNAR KOORDINATER
    {
		name: "CCC, Karlstad",
		type: "Nybyggnad av kongress- och konsertanläggning samt ombyggnad av kontor.",
		amount: "24 000 kvm",
		builder: "Mötesplatsen KB",
		finished: "2011",
		architect: "Mondo Arkitekter",
		contractor: "Consto",
		pos: [],
		address: "Tage Erlandergatan, Karlstad",
		img: Karlstad,
	},
    */
	{
		name: "Bryggudden, Karlstad",
		type: "Bostadsrättsförening Bryggudden",
		amount: "62 lägenheter",
		builder: "Löfberg Fastigheter AB",
		finished: "2015",
		architect: "Tengbom",
		contractor: "Consto",
		pos: [59.37501408024066, 13.510409373937893],
		address: "Matrosgränd, Karlstad",
		img: Karlstad,
	},
	{
		name: "Bryggudden, Karlstad",
		type: "Bostadsrättsförening Redartorget",
		amount: "204 lägenheter",
		builder: "Löfberg Fastigheter AB",
		finished: "2016",
		architect: "Tengbom",
		contractor: "Consto",
		pos: [59.37565608746972, 13.510417889417548],
		address: "Matrosgränd, Karlstad",
		img: Karlstad,
	},
	{
		name: "Bryggudden, Karlstad",
		type: "Hyresrätter - Galeasen",
		amount: "167 lägenheter",
		builder: "SPG",
		finished: "2015",
		architect: "Tengbom",
		contractor: "Consto",
		pos: [59.376688479016885, 13.509821805841652],
		address: "Ahlmarksgatan, Karlstad",
		img: Karlstad,
	},
	{
		name: "Skåre, Karlstad",
		type: "Brf Nickeboa",
		amount: "26 lägenheter",
		builder: "Prepart AB",
		finished: "2016",
		architect: "Södergruppen Arkitekter",
		contractor: "Consto",
		pos: [59.40326471141159, 13.49677342030634],
		address: "Råtorpsvägen, Karlstad",
		img: Karlstad,
	},
	{
		name: "Handelskvarterat Valvet, Karlstad",
		type: "Galleria, kontor och bostäder (hyresrätter)",
		amount: "7 000 kvm handel, 3 000 kvm kontor, 52 lägenheter",
		builder: "Qwerty Fastigheter AB",
		finished: "2016",
		architect: "Mondo Arkitekter",
		contractor: "Consto",
		pos: [59.38001023158388, 13.503373572295686],
		address: "Östra torggatan, Karlstad",
		img: Karlstad,
	},
	{
		name: "Kv Gasugnen, Örebro",
		type: "Hyresrätter och lokaler/garage",
		amount: "147 hyreslägenheter och lokaler om cirka 1 500 kvm",
		builder: "SPG",
		finished: "2016",
		architect: "White Arkitekter",
		contractor: "Consto",
		pos: [59.26383639681896, 15.23597384272539],
		address: "Rudbecksgatan, Örebro",
		img: Karlstad,
	},
	/*
	{
		name: "",
		type: "",
		amount: "",
		builder: "",
		finished: "",
		architect: "",
		contractor: "",
		pos: [],
		address: "",
        zip: "",
		img: Karlstad,
	},
    */
] as Location[];
