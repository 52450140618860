import React from "react";
import { Fardiga } from "../../../../reusable";
import FastOne from "./../../../../assets/images/fastigheter/fast-one.png";
import FastTwo from "./../../../../assets/images/fastigheter/fast-two.png";
import FastThree from "./../../../../assets/images/fastigheter/fast-three.png";
import FastTen from "./../../../../assets/images/fastigheter/fast-ten.png";
import FastTwentythree from "./../../../../assets/images/fastigheter/fast-twentythree.jpeg";
import Maps from "../map/map";
import img39 from "./../../../../assets/images/fastigheter/img-39.jpg";
import img40 from "./../../../../assets/images/fastigheter/img-40.jpg";
import img41 from "./../../../../assets/images/fastigheter/img-41.jpg";
import img42 from "./../../../../assets/images/fastigheter/img-42.png";
import img44 from "./../../../../assets/images/fastigheter/img-44.jpg";
import img45 from "./../../../../assets/images/fastigheter/img-45.jpg";
import img46 from "./../../../../assets/images/fastigheter/img-46.jpg";

const Body = () => {
	return (
		<div className="w-full flex flex-col items-center pb-[5%] pt-[5%]">
			<div className="w-[90%]">
				<div className="w-full grid grid-cols-3 gap-10 xl:grid-cols-2 md:grid-cols-1">
					<Fardiga pagaende
						image={FastOne}
						location="STRÖMSTAD"
						type="HYRESRÄTTER"
						title="MYREN"
						infoOne="Färdigställt"
						infoTwo="2023"
						href="projekt/myren"
					></Fardiga>
					<Fardiga pagaende
						image={FastThree}
						location="KARLSTAD"
						type="BOSTADSRÄTTER"
						title="GOLFKLUBB"
						infoOne="Färdigställt"
						infoTwo="2023"
						href="projekt/karlstadsgolfklubb"
					></Fardiga>
					<Fardiga pagaende
						image={img45}
						location="KARLSTAD"
						type="BOSTADSRÄTTER"
						title="BRYGGUDDEN"
						infoOne="Färdigställt"
						infoTwo="2024"
						href="projekt/barken"
					></Fardiga>
					<Fardiga pagaende
						image={img39}
						location="KRISTINEHAMN"
						type="HYRESRÄTTER"
						title="SANNAKAJEN"
						infoOne="Färdigställt"
						infoTwo="2024"
						href="projekt/sannakajen"
					></Fardiga>
					<Fardiga pagaende
						image={img41}
						location="STORVETA"
						type="HYRESRÄTTER &amp; SKOLA"
						title="Fullerö"
						infoOne="Färdigställt"
						infoTwo="2025"
						href="projekt/fullero"
					></Fardiga>
					<Fardiga pagaende
						image={img42}
						location="SYDKOSTER"
						type="STUGOR FÖR UTHYRNING"
						title="SYD-KOSTER"
						infoOne="Färdigställt"
						infoTwo="2026"
					></Fardiga>
					<Fardiga pagaende
						image={img44}
						location="SANDVIKEN"
						type="HYRESRÄTTER"
						title="KYRKÅSEN"
						infoOne="Färdigställt"
						infoTwo="2025"
						href="projekt/kyrkasen"
					></Fardiga>
					<Fardiga pagaende
						image={img46}
						location="STRÖMSTAD"
						type="HYRESRÄTTER"
						title="NJORD HOTELL"
						infoOne="Färdigställt"
						infoTwo="2025"
					></Fardiga>
				</div>
			</div>
			<div className="w-[90%]">
				<Maps />
			</div>
		</div>
	);
};

export default Body;
