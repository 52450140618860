import Karlstad from "./../../../assets/images/fastigheter/fast-one.png";
import Stockholm from "./../../../assets/images/fastigheter/fast-one.png";
import { Location } from "./property";

export default [
	{
		name: "Karlstads golfklubb, Karlstad",
		type: "Bostadsrättsförening Järnsjuan",
		start: "2022 Q2",
		amount: "18 villor",
		builder: "Hofbro Fastigheter",
		finished: "2023",
		architect: "Huspartner",
		contractor: "Huspartner",
		pos: [59.43383861481814, 13.528501381249898],
		address: "Höja 510",
		zip: "655 92",
		img: Karlstad,
	},
	{
		name: "Bryggudden – BRF Br Persson",
		type: "Bostadsrätter",
		start: "2023 Q3",
		amount: "22 lägenheter",
		builder: "IDUN Fastigheter",
		finished: "2024",
		architect: "Tengbom",
		contractor: "NCC",
		pos: [59.37726129826776, 13.510924951390175],
		address: "Ahlmarksgatan, Karlstad",
		img: Karlstad,
	},
	{
		name: "Kristinehamn – Sannakajen",
		type: "Hyresrätter",
		start: "2022 Q3",
		amount: "126 lägenheter",
		builder: "Strömvold",
		finished: "2024",
		architect: "Tengbom",
		contractor: "NCC",
		pos: [59.30933797504141, 14.094720726485402],
		address: "Nyhamnsvägen, Kristinehamn",
		img: Karlstad,
	},
	{
		name: "Uppsala – Fullerö",
		type: "Hyresrätter, Skola & Förskola",
		start: "2023 Q3",
		amount: "100 lägenheter, f-6 Skola, 8 avdelningar förskola",
		builder: "IDUN Fastigheter",
		finished: "2025",
		architect: "Metod",
		pos: [59.940505925993484, 17.67334232038494],
		address: "Meteorvägen, Storvreta",
		img: Karlstad,
	},
	{
		name: "Strömstad - Njord",
		type: "Hotell",
		start: "2023 Q3",
		amount: "300 hotellrum",
		builder: "Water Strömstad AB",
		finished: "2026",
		architect: "OlssonLyckefors",
		pos: [58.9314615076522, 11.174137722791158],
		address: "Trädgårdsgatan, Strömstad",
		img: Karlstad,
	},
	/*
    !!SAKNAR KOORDINATER!!
	{
		name: "Syd-Koster",
		type: "Stugor för uthyrning",
        start: "2023 Q3",
		amount: "30 stugor och camping",
		builder: "Water Strömstad AB",
		finished: "2026",
		architect: "OlssonLyckefors",
		pos: [],
		address: "Kostergården, Sydkoster",
		zip: "",
		img: Karlstad,
	},
    */
	{
		name: "Bålsta, Håbo kommun",
		type: "Hyresrätter",
		amount: "260 lägenheter",
		builder: "SPG",
		finished: "2023",
		architect: "Tengbom BACA",
		contractor: "NCC",
		pos: [59.563109244652914, 17.540566512277866],
		address: "Herrgårdsgatan, Bålsta",
		img: Karlstad,
	},
	{
		name: "Bollmora backe, Tyresö",
		type: "Bostadsrättsförening Almandinen",
		amount: "145 lägenheter",
		builder: "SPG",
		finished: "2023",
		architect: "Vida Arkitektkontor",
		contractor: "NCC",
		pos: [59.24185732427797, 18.222589665525565],
		address: "Granitvägen, Tyresö",
		img: Karlstad,
	},
	/*
	{
		name: "",
		type: "",
		amount: "",
		builder: "",
		finished: "",
		architect: "",
		contractor: "",
		pos: [],
		address: "",
        zip: "",
		img: Karlstad,
	},
    */
] as Location[];
